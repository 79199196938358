//@font-face {
//  font-family: 'Nunito Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Nunito Sans Light'), local('NunitoSans-Light'), url(../fonts/Nunito_Sans/NunitoSans-Light.ttf) format('woff');
//}
//
//@font-face {
//  font-family: 'Nunito Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'), url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf) format('woff');
//}
//
//@font-face {
//  font-family: 'Nunito Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'), url(../fonts/Nunito_Sans/NunitoSans-Bold.ttf) format('woff');
//}

/* latin */
@font-face {
  font-family: 'Droid Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Droid Sans'), local('DroidSans'), url(../fonts/Droid_Sans/DroidSans.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
  font-family: 'Droid Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Droid Sans Bold'), local('DroidSans-Bold'), url(../fonts/Droid_Sans/DroidSans-Bold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: local('Lobster'), local('Lobster-Regular'), url(../fonts/Lobster/Lobster-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}