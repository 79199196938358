// COLORS
$red: #ed2129;
$green: #6EB956;
$black: #191C1B;
$white: #FFFFFE;

$gap: 40px;
$border: 7px;

$brand-success: $green;

// Bootstrap
$icon-font-path: '/assets/fonts/bootstrap/';

$font-family-sans-serif: 'Droid Sans', sans-serif;
$font-family-serif: 'Lobster', sans-serif;

//$border-radius-base: 0px;
//$border-radius-small: 0px;
//$input-height-small: 25px;

$input-border-radius: 0;
$btn-border-radius-base: 0;

//$navbar-height: 10px;
$navbar-default-bg: $green;
$navbar-default-border: 0;
$navbar-default-color: $white;
$navbar-default-link-color: $white;
$navbar-default-link-hover-color: $red;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $white;
$navbar-default-link-active-bg: transparent;
$navbar-default-toggle-border-color: transparent;
$navbar-default-toggle-icon-bar-bg: transparent;
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-brand-hover-bg: transparent;
$navbar-border-radius: 0;

$grid-float-breakpoint: 992px;

$link-color: $red;

// for contact form
//$state-danger-text: $w-red;

//$font-size-base: 15px;

//$font-size-h1: floor(($font-size-base * 2.0));
//$font-size-h2: floor(($font-size-base * 1.5));
//$font-size-h3: $font-size-base;
//$font-size-h4: ceil(($font-size-base * 0.95));
//$font-size-h5: ceil(($font-size-base * 0.90));
//$font-size-h6: ceil(($font-size-base * 0.85));

//$dl-horizontal-offset: 100px;

//@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootstrap";

// cookie approval
//$ka-cookie-bg: $green;
//$ka-cookie-color: $white;
$ka-cookie-link-color: $green;
$ka-cookie-link-color-hover: $green;
$ka-cookie-btn-bg: $green;
$ka-cookie-btn-color: $white;
$ka-cookie-position: top-right;

@import "../website/site/plugins/cookie/cookie";

//$pswp__show-hide-transition-duration: 333ms !default;
//$pswp__controls-transition-duration: 333ms !default;
//$pswp__background-color: #000 !default;
//$pswp__placeholder-color: #222 !default;
//$pswp__box-sizing-border-box: true !default; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
//$pswp__root-z-index: 1500 !default;
$pswp__assets-path: '/assets/images/photoswipe/'; // path to skin assets folder (preloader, PNG and SVG sprite)
//$pswp__error-text-color: #CCC !default; // "Image not loaded" text color
//$pswp__include-minimal-style: true !default;

@import "../bower_components/photoswipe/src/css/main";
@import "../bower_components/photoswipe/src/css/default-skin/default-skin";

//@import "css-debug";
//@import "col-middle";
//@import "flexthis";
@import "fonts";

.uniform__potty {
  position: absolute;
  left: -9999px;
}

.img-responsive {
  @extend .img-responsive;
  margin: 0 auto;
}

figure {
  img {
    @extend .img-responsive;
  }
}

//[class*='col-'] > h1:first-child,
//[class*='col-'] > h2:first-child,
//[class*='col-'] > h3:first-child,
//[class*='col-'] > h4:first-child,
//[class*='col-'] > h5:first-child,
//[class*='col-'] > h6:first-child {
//  margin-top: 0;
//  //color: red; // for debug
//}

body {
  letter-spacing: 0.02em;
  line-height: 1.45em;
  hyphens: auto;
}

// HEADLINES

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 floor($gap/2) 0;

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    margin: 0 0 $gap 0;
  }
}

h1 {
  font-family: $font-family-serif;
  color: $red;
}

h2 {
  font-family: $font-family-serif;
}

h3 {
  font-size: $font-size-base;
  font-weight: bold;
  margin: floor($gap/2) 0 floor($gap/4) 0;
  //margin: 0;
}

h4 {
  font-size: floor(($font-size-base * 0.95));
  font-weight: bold;
  margin: floor($gap/2) 0 floor($gap/4) 0;
}

// MENUE

.nav-placeholder {
  height: 85px;
}

.navbar {
  //margin-bottom: $gap * 1.5;
  //text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1em;
  letter-spacing: 0.05em;

  .navbar-brand {
    padding: 0;
    //margin-top: -15px;
    width: 140px;
    //margin-left: 0 !important;
  }

  .navbar-header {

    .navbar-toggle {

      .hamburger {
        transform: rotate(90deg);
      }

      .menu {
        color: #fff;
        font-size: 1.2em;
        margin-right: 20px;
        vertical-align: sub;
      }

      &.navbar-toggle-home {

        @media (min-width: $screen-sm-min) {
          width: 100%;
          text-align: left;
          padding: 9px 15px;
          margin: 0;

          .menu {
            line-height: 48px;
          }

          .hamburger {
            float: right;
          }
        }
      }

    }
  }

  .navbar-nav {

    margin-top: 30px;

    .active a {
      color: $red;
    }

    @media (min-width: $grid-float-breakpoint) {

      margin-top: 0;

      .active {
        a span {
          color: $white;
          padding-bottom: 10px;
          border-bottom: $border solid $red;
        }
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .navbar-nav {
      display: inline-block;
      float: none;
      vertical-align: top;
    }

    .navbar-collapse {
      text-align: center;
    }
  }
}

// SUBMENU

.submenu {

  margin-bottom: $gap;

  a:hover, a:active, a:focus {
    text-decoration: none;
  }

  li.active {
    span {
      border-bottom: 2px solid $green;
    }
  }

  @media (min-width: $screen-sm-min) {

    margin-bottom: 0;

    h1 {
      color: $white;
      margin: 0;

      font-size: floor(($font-size-base * 1.7));
      line-height: 1.4;

      @media (min-width: $screen-md-min) {
        font-size: floor(($font-size-base * 2));
      }
    }

    a {
      color: $white;
    }

    ul {
      list-style: none;
      padding: 0;

      border-right: solid $border $green;

      margin-bottom: 0;
      margin-right: -($gap);

      li {
        margin-top: 20px;
        margin-right: 20px;
        hyphens: auto;
        line-height: 1.8em;

        span {
          border-bottom: 2px solid $red; // wird benötigt, damit es beim active keinen Sprung gibt
        }

        &.active {

          text-decoration: none;

          span {
            border-bottom: 2px solid $green;
          }
        }
      }
    }

    background: $red;

    margin-top: -($gap + $border);
    margin-right: -($gap + $border);
    padding: $gap;

  }
}

main, .main {
  margin-top: $gap;
}

// BORDER

.border {
  @media (min-width: $screen-sm-min) {
    border: $border solid $green;
    border-bottom: none;
    padding: $gap;
  }
}

.border-bottom {
  @media (min-width: $screen-sm-min) {
    border-top: $border solid $green;
    margin-bottom: $gap;
  }
}

.border-bottom-info {
  background: $red;
  color: $white;
  font-size: 0.8em;

  .border {

    padding-top: $gap;

    @media (min-width: $screen-sm-min) {
      border: $border solid $green;
      border-top: none;
      padding: $gap;
      margin-bottom: floor($gap * 2);
    }

    //-webkit-column-count: 3; /* Chrome, Safari, Opera */
    //-moz-column-count: 3; /* Firefox */
    //column-count: 3;

    .info-logo {
      text-align: center;

      @media (min-width: $screen-sm-min) {
        text-align: right;
      }
    }

    .details {
      background: $white;
      color: $black;

      margin-top: $gap;
      margin-left: -($grid-gutter-width / 2);
      margin-right: -($grid-gutter-width / 2);

      padding-top: floor($gap / 2);
      padding-left: ($grid-gutter-width / 2);
      padding-right: ($grid-gutter-width / 2);

      @media (min-width: $screen-sm-min) {
        padding: $gap;
        margin-bottom: -(floor($gap * 2) + $border);
        margin-left: 0;
        margin-right: 0;
      }

      .columns {

        p {
          margin: 0;
        }

        border-top: $border solid $green;
        padding-top: floor($gap / 4);

        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;

        margin-bottom: floor($gap / 2);

        @media (min-width: 500px) {
          -webkit-column-count: 3; /* Chrome, Safari, Opera */
          -moz-column-count: 3; /* Firefox */
          column-count: 3;

          margin-bottom: floor($gap / 2);
        }

        ol {
          list-style-position: inside;
          margin: 0;
          padding: 0;

        }
      }
    }
  }
}

// HOME

.theme-home {

  header {
    position: relative;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) {
        left: calc((100% - #{$container-sm} + #{$grid-gutter-width}) / 2);
      }

      /* Medium devices (desktops, 992px and up) */
      @media (min-width: $screen-md-min) {
        left: calc((100% - #{$container-md} + #{$grid-gutter-width}) / 2);
      }

      /* Large devices (large desktops, 1200px and up) */
      @media (min-width: $screen-lg-min) {
        width: 20%;
        left: calc((100% - #{$container-lg} + #{$grid-gutter-width}) / 2);
      }

      .content {
        background: $white;
        padding: floor($gap*1.5) floor($gap/2) 0 floor($gap/2);
      }

      .curved {
        background: $white;
        //border-bottom-left-radius: 120%;
        //border-bottom-right-radius: 120%;

        margin-top: -63px;

        height: 100px;
        width: 100%;
        //border-radius: 0 0 50% 50% / 60%;

        border-bottom-left-radius: 40%;
        border-bottom-right-radius: 40%;
      }
    }

    .teaser {
      //position: absolute;
      //margin-bottom: $gap;
    }

    nav {
      margin-top: -25px;
    }
  }

  .service {
    //margin-top: $gap;
    margin-bottom: $gap;

    .picture {
      div {
        width: 90%;

        position: relative;
        //border: 5px solid #f00;

        &:after {
          content: " ";
          position: absolute;
          z-index: -1;
          top: 10%;
          left: 10%;
          right: -10%;
          bottom: -10%;
          border: $border solid $red;
        }
      }
    }
  }
}

.theme-default {

}

.theme-menu {
  .menu-content {

    //min-height: 1000px;

    @media (min-width: $screen-md-min) {
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
      //orphans: 3; // funktioniert nicht
    }
  }

  .continue {
    font-family: $font-family-serif;
    color: $red;
    font-size: 25px;
    margin-top: 20px;

    img {
      display: block;
      width: 70px;
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
    }

  }
}

.theme-col-2 {

}

.theme-gallery {

  .gallery {
    figure {
      display: inline-block;

      img {
        width: auto;
        margin: 0 0 30px 0;
      }
    }
  }
}

/// FOOTER
footer {
  background: $black;
  color: $white;
  padding-top: $gap;
  padding-bottom: $gap;

  font-size: 0.8em;

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .slogan {
    font-size: $font-size-h1;
    font-family: $font-family-serif;
    margin-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    .logo-footer {
      width: 100px;
      margin: 0 0 -10px -60px;
    }
  }

  .text {
    margin-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }

}